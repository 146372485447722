import * as React from 'react';
import {FC, useEffect, useState} from 'react';
import {IbanElement, useElements, useStripe} from '@stripe/react-stripe-js';
import IbanForm from "./IBANForm";
import {Client, handleApiError} from "../../services/ApiService";
import {connect, ConnectedProps} from "react-redux";
import {toast} from "react-toastify";

const mapState = ({member, auth}) => ({
    member,
    auth
})

const connector = connect(mapState, null);

type PropsFromRedux = ConnectedProps<typeof connector>

const PaymentSetupForm: FC<PropsFromRedux & any> = ({member, auth}) => {
    const stripe = useStripe();
    const elements = useElements();
    const [clientSecret, setClientSecret] = useState();

    useEffect(() => {
        Client.Auth
            .authGetWithAction(auth.Key, 'Member', member.MemberID, 'getClientSecret')
            .then((res) => {
                setClientSecret(res.data);
            })
            .catch(handleApiError);
    }, []);

    const handleSubmit = async (event) => {
        // We don't want to let default form submission happen here,
        // which would refresh the page.
        event.preventDefault();

        if (!stripe || !elements) {
            // Stripe.js has not yet loaded.
            // Make sure to disable form submission until Stripe.js has loaded.
            return;
        }

        const iban = elements.getElement(IbanElement);

        // For brevity, this example is using uncontrolled components for
        // the accountholder's name and email. In a real world app you will
        // probably want to use controlled components.
        // https://reactjs.org/docs/uncontrolled-components.html
        // https://reactjs.org/docs/forms.html#controlled-components

        const accountholderName = event.target['formFirstName'];
        const email = event.target['formEmail'];

        const result = await stripe.confirmSepaDebitSetup(clientSecret, {
            payment_method: {
                sepa_debit: iban,
                billing_details: {
                    name: accountholderName.value,
                    email: email.value,
                },
            }
        });

        if (result.error) {
            // toast.error('Es ist ein Fehler aufgetreten');
        } else {
            Client.Auth
                .authPostWithAction(auth.Key, 'Member', member.MemberID, 'updateIBAN', {
                    PaymentMethod: result.setupIntent.payment_method
                }).then(() => {
                toast.success('IBAN wurde hinzugefügt.');
                Client.AuthVendor
                    .authGet(auth.Key, 'LoginLink', member.Member.VendorID)
                    .then((res) => {
                        window.open(res.data.Link, '_blank')
                    }).catch(handleApiError);
            }).catch(handleApiError);
        }
    };

    return (
        <IbanForm onSubmit={handleSubmit} disabled={!stripe}/>
    );
}

export default connector(PaymentSetupForm);
